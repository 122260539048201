<template>
  <v-row no-gutters> 
    <v-col cols="12">
      <v-row class="py-3"> 
        <v-col cols="12" sm="6" md="6" class="d-flex align-center px-2">
          <span class="text-h6 font-weight-bold mx-5 primary--text">
            Listado de Clientes
          </span>  
        </v-col>
        <v-col cols="12" class="pt-0 mb-2 d-flex align-center px-6">
          <div class="mr-2 mt-2" style="min-width: 350px;">
            <search-expand 
              v-model="search_cli" 
              :load="loading"
              placeholder="Buscar Cliente"
            />
          </div>
          <v-btn
            color="icono"
            class="mt-2"
            outlined
            small
            @click="updateClients"
            :disabled="loading"
          >               
            <v-icon size="22" 
            :left="!$vuetify.breakpoint.xsOnly">mdi-update</v-icon>             
            <span v-if="!$vuetify.breakpoint.xsOnly">Actualizar</span>        
          </v-btn>
          <btn-horario v-model="filtrosC.horario" />      
          <btn-geolocalizacion v-model="filtrosC.geo" />      
          <btn-estatus v-model="filtrosC.estatus" />     
        </v-col>
        <v-col cols="12" class="py-0">
          <v-divider />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="12" class="px-0">
      <data-clients 
        :data="itemsClients"
        :search="search_cli"
        :headers="headersClient"
        :loadingData="loading"
      >
        <template v-slot:actionInfo="{client}">
          <v-menu
            v-if="$hasPermission('ruta-vendedores.clientes.frecuencia') || $hasPermission('ruta-vendedores.clientes.geolocalizacion')"
            bottom
            left
            min-width="200"
            offset-y
            origin="top right"
            transition="scale-transition"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn  
                v-bind="attrs"
                v-on="on" 
                plain 
                small 
                color="icono" 
                class="px-0"
              >
                <v-icon size="20"  color="icono">mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list
              class="py-0"
            >
              <v-list-item-group
                color="primary"
              >
                <template v-if="$hasPermission('ruta-vendedores.clientes.frecuencia')">
                  <v-list-item class="py-2" @click="actionHorario(client)">
                    <v-list-item-icon class="my-2 ml-1 mr-3 align-self-center">
                      <v-icon left color="blue-grey">mdi-alarm-check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Editar Horario</v-list-item-title>
                  </v-list-item>
                  <v-divider/>
                </template>
                <v-list-item 
                  v-if="$hasPermission('ruta-vendedores.clientes.geolocalizacion')" 
                  class="py-2" @click="actionUbicacion(client)"
                >
                  <v-list-item-icon class="my-2 ml-1 mr-3 align-self-center">
                    <v-icon left color="blue-grey">mdi-map-marker</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Editar Geolicalización</v-list-item-title>
                </v-list-item>      
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
      </data-clients>
    </v-col>
    <modal-horario 
      v-if="$hasPermission('ruta-vendedores.clientes.frecuencia')" 
      v-model="toggle_horario" 
      :data-visita="visita_currente"
    />
    <modal-ubicacion 
      v-if="$hasPermission('ruta-vendedores.clientes.geolocalizacion')" 
      v-model="toggle_map" 
      :ubication="ubicacion_current"
    />
    <!-- <v-col cols="5" class="actions--style" style="position: relative !important">
      <view-maps :points="markers_cli" :loadData="false" v-if="!loading"/>
      <v-overlay 
        v-else
        :value="true"
        color="white" 
        absolute
        opacity="1"
      >
        <div class="d-flex flex-column justify-center">
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"  
            class="mx-6"        
          ></v-progress-circular> <br>
          <span class="primary--text py-5">Obteniendo Coordenadas</span>
        </div>
      </v-overlay>
    </v-col>   -->
  </v-row>
</template>
<script>
export default {
  name: 'ClientesVendedor',
  components: { 
    DataClients: () => import(
      /* webpackChunkName: "data-clients" */
      '@/views/Clientes/Components/DataClients.vue'
    ),
    ViewMaps: () => import(
      /* webpackChunkName: "view-maps" */
      '@/widgets/ViewMaps'
    ),
    ModalHorario: () => import(
      /* webpackChunkName: "modal-horario" */
      '@/views/Clientes/Components/ModalHorario.vue'
    ),
    ModalUbicacion: () => import(
      /* webpackChunkName: "modal-ubicacion" */
      './ModalUbicacion.vue'
    ),
    BtnHorario: () => import(
      /* webpackChunkName: "btn-horario" */
      '@/views/Clientes/Components/Filtros/BtnHorario.vue'
    ),
    BtnGeolocalizacion: () => import(
      /* webpackChunkName: "btn-horario" */
      '@/views/Clientes/Components/Filtros/BtnGeolocalizacion.vue'
    ),
    BtnEstatus: () => import(
      /* webpackChunkName: "btn-estatus" */
      '@/views/Clientes/Components/Filtros/BtnEstatus.vue'
    ),
  },
  props:{
    clientes: {
      type: Array,
      default: () => ([]),
    },
    loading:{
      type: Boolean,
      default: false
    },
  },
  data:() =>({
    headersClient: [
      { text: 'Cod.', value: 'co_cli', class: 'header-data', width: '100', active: true, },
      { text: 'Cliente', value: 'cli_des', class: 'header-data', active: true,},
      { text: '', value: 'info', class: 'header-data', active: true, sortable: false},
    ],
    search_cli: '',
    toggle_horario: false,
    toggle_map: false,
    visita_currente: {},
    ubicacion_current: {},
    filtrosC:{
      geo: '',
      estatus: '',
      horario: '',
    },
  }),
  methods:{
    actionHorario(visita){
      this.toggle_horario = true;
      this.visita_currente = {...visita.visita_programadas, co_cli:visita.co_cli};
    },
    actionUbicacion(cliente){
      this.toggle_map = true;
      this.ubicacion_current = {
        position:{
          lat: cliente.lat != null ? parseFloat(cliente.lat.trim()) : null,
          lng: cliente.lng != null ? parseFloat(cliente.lng.trim()) : null,
        },
        co_cli:cliente.co_cli
      };
    },
    updateClients(){
      this.$emit('update');
    }
  },
  computed:{
    markers_cli(){
      return this.clientes.length > 0 ? this.clientes.filter(cli => cli.lat != null).map(item => ({
        name: item.cli_des,
        position: {
          lat: parseFloat(item.lat.trim()),
          lng: parseFloat(item.lng.trim()),
        }
      })) : []
    },
    itemsClients () {
      let data = [];
      data = this.clientes.map((item) => {
        return {
          ...item,
          horario: item.visita_programadas ? 'Frecuencia registrada' : 'Sin frecuencia',
          estatus: item.inactivo.toString(),
          geo: item.lat !== null && item.lng !== null ? '1' : '0',
        }
      })

      const CustomFiltersKeys = Object.keys(this.filtrosC)
      return data.filter((item) => {
        return CustomFiltersKeys.every(keyFiltro => {
          if(this.filtrosC[keyFiltro].length === 0) {
            return true;
          }
          return typeof this.filtrosC[keyFiltro] === 'object' 
            ? this.filtrosC[keyFiltro].includes(item[keyFiltro]) 
            : (this.filtrosC[keyFiltro].toString()).toLowerCase().includes((item[keyFiltro].toString()).toLowerCase())
        })
      })
    },
  }
}
</script>